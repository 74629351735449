$heading-color: yellow;
$sidebar-href-color:  #009ECE;
$background-image-sidebar: url("../image/stardust.png");
$background-image-content: url("../image/stardust.png");
$paragraph-color: white;
$code-highlight-color: #333;

body {
  padding: 0px;
  margin: 0px;
  background-color: black;
  font-family: Arial;
  color: $heading-color;
}
nav {
  a {
    display: block;
    color: $sidebar-href-color;
    text-align: center;
    text-decoration: none;
    margin: 0px 20px 3px 20px;
    font-size: 25px;
    &:hover {
      background-color: $paragraph-color;
    }
  }
}
div.sidebar{
  overflow-y: auto;
  background-image: $background-image-sidebar;
  position: relative;
  width: 22%;
  height: 100vh;
  float: left;
  h1{
    text-align: center;
    font-size: 3em;
    margin-top: 34px;
  }
  p {
    font-size: 30px;
    text-align: left;
    padding: 5px 15px;
    color: $paragraph-color;
  }
  div.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 10px;
    margin-top: 20px;
    overflow: auto;
    bottom: 0px;
    div.avatar {
      order: 1;
      background-image: url("https://www.gravatar.com/avatar/ca122022e6b6f4438e5fc1a67c57e285");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100px;
      height: 100px;
    }
    div.contact {
      order: 2;
      padding: 0px 25px 10px 25px;

      a {
        color: $sidebar-href-color;
        text-decoration: none;
        display: block;
        font-size: 22px;
        text-align: center;
        &:hover {
          background-color: $paragraph-color;
        }
      }
    }
  }
}

div.topbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
  height: auto;
  z-index: 1;
  background-color: rgba(0,0,0,0.8);
  h1 {
    color: $heading-color;
    text-align: center;
  }

  input[type="checkbox"] {
    display: none;
    &:checked ~ nav {
      display: block;
    }
  }

  nav {
    display: none;
  }

  label[for="menu-trigger"] {
    position: fixed;
    top: 20px;
    right: 24px;
    border-bottom: 17px double $heading-color;
    border-top: 6px solid $heading-color;
    content:"";
    height: 5px;
    width:30px;
    cursor: pointer;
  }
}

div.content{
  background-image: $background-image-content;
  position: relative;
  width: 78%;
  height: 100vh;
  float: left;
  overflow-y: scroll;
}

div.post {
  margin: 0px 20px;
  padding: 0px 20px 10px 20px;
  background-color: rgba(0,0,0, 0.2);
  border-radius: 25px;
  color: $paragraph-color;
  a {
    text-decoration:none;
    color: $sidebar-href-color;
    &:hover {
      text-decoration: underline;
    }
  }

  h1{
    color: $heading-color;
    font-size: 2em;
    padding-top: 20px;
    margin-bottom: 10px;
    overflow-wrap: break-word !important;
    a {
      color: $heading-color;
    }
  }

  h2{
    color: $heading-color;
    font-size: 1.75em;
    padding-top: 20px;
    margin-bottom: 10px;
    overflow-wrap: break-word !important;
    a {
      color: $heading-color;
    }
  }

  h3{
    color: $heading-color;
    font-size: 1.5em;
    padding-top: 20px;
    margin-bottom: 10px;
    overflow-wrap: break-word !important;
    a {
      color: $heading-color;
    }
  }
  hr {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  div.date-address-category{
    font-size: 1.2em;
    span.category-icon {
      content: url("../image/tags.svg");
      max-width: 1em;
      margin-left: 25px;
      margin-right: 5px;
    }
    a {
      color: $heading-color;
    }
  }

  p {
    color: $paragraph-color;
    max-width: 100ch;
    font-size: 1.5em;

    a {
      color: $sidebar-href-color;
    }
  }

  li {
    color: $paragraph-color;
    max-width: 90ch;
    font-size: 1.5rem;
    margin: 0.25rem 0 0 0;
  }


  div.category-tree {
    padding: 20px 30px;
    ul.parent, ul.parent ul {
      list-style-type: none;
      color: $paragraph-color;
      font-size: 1.2em;
      background: url("../image/vline.png") repeat-y;
      margin: 0;
      padding: 0;
    }
    ul.parent ul {
      margin-left: 22px;
    }
    ul.parent a {
      color: $paragraph-color;
      text-decoration:none;
      &:hover {
        text-decoration: underline;
      }
    }

    ul.parent li {
      background: url("../image/node.png") no-repeat;
      margin: 5px 0 15px 0;
      padding: 0 12px;
      line-height: 20px;
    }
    ul.parent li:last-child {
      background:  url("../image/stardust.png");
      padding: 0px;
      div.last {
        background-color: rgba(0,0,0,0.2);
        div.node{
          background: url("../image/lastnode.png") no-repeat;
          padding: 0 12px;
        }
      }
    }
  }
}

div.pagination {
  text-align: center;
  margin: 30px auto 60px auto;
  padding: 0px 20px 20px 20px;
  a.previous, a.next {
    float: left;
    width: 40%;
    padding: 10px 10px;
    font-size: 20px;
    color: $heading-color;
    text-decoration: none;
    border: 1px;
    border-radius: 25px;
    &:hover {
      background-color: rgba(0,0,0,0.2);
    }
  }
}

div.highlight {
  pre.highlight {
    overflow-x: auto;
  }
}

code.highlighter-rouge {
  color: $code-highlight-color;
  background-color: rgba(241, 250, 140, 0.9);
  border-radius: 3px;
}

figure {
  margin-left: 5px;
  margin-right: 5px;
  overflow-x: auto;
  &.image {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  & > img {
    max-width: 100%;
  }
}

table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

table {
  margin-left: auto;
  margin-right: auto;
}

th, td {
  padding: 5px 10px 5px 10px;
}

@media  (max-width: 768px) {
  div.sidebar{
    display: none;
  }
  div.topbar{
    display: block;
  }
  div.content{
    width: 100%;
    display: block;
    margin-top: 78px;
    height: 100%;
    z-index: 0;
  }
}
